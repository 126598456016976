// Here you can add other styles
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito", sans-serif;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  overflow-x: clip;
  overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #07070c;
}

p,
span,
label {
  font-family: "Nunito", sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
button,
.btn,
a {
  color: #07070c;
  font-family: "Lato", sans-serif !important;
}

.breadcrumb {
  align-items: center;

  .breadcrumb-item {
    a {
      color: #767682;
      text-decoration: none;
      font-family: "Nunito", sans-serif !important;
      font-weight: 700;
      font-size: 14px;

      &:hover {
        color: #767682;
      }
    }

    &.active {
      color: #767682;
      font-family: "Nunito", sans-serif !important;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.btn-primary {
  background-color: #0176d3;
  border: 1px solid #0176d3;
  border-color: #0176d3;
  color: white;
}

.btn-primary:hover {
  background-color: #0176d3c4;
  border-color: #0176d3c4;
  border: 1px solid #0176d3c4;
}

.btn-outline-primary {
  background-color: #fff;
  border: 1px solid #0176d3;
  border-color: #0176d3;
  color: #0176d3;
}

.btn-outline-primary:hover {
  background-color: #0176d3c4;
  border-color: #0176d3c4;
  border: 1px solid #0176d3c4;
}

.btn-danger {
  background: #f77575;
  border: 1px solid #f77575;
  color: white;
}

.btn-danger:hover {
  background-color: #fe8383;
  border-color: #fe8383;
  border: 1px solid #fe8383;
  color: white;
}

.header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-top: 10px;

  .header_name {
    margin-bottom: 0;
    color: #1a1a2e;
    font-size: 28px;
  }

  .input-group {
    input {
      background-color: #f1f1f1;
      border-right: 0;
      border-color: #a3a3ab;

      &:focus {
        border-color: #a3a3ab;
        box-shadow: none;
      }
    }

    button {
      background-color: #f1f1f1;
      border-color: #a3a3ab;
      border-left: 0;

      img {
        width: 15px;
      }
    }
  }
}

.header-divider {
  border-top: 1px solid #f5f5f5;
}

.previewImg {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.sidebar {
  background-color: #f8f8f8;

  .sidebar-brand {
    background-color: #f8f8f8;
  }

  .sidebar-nav {
    padding: 30px 10px 20px;
    overflow: initial;

    .nav-link {
      color: #001e35;
      display: flex;
      align-items: center;
      font-size: 16px;

      .side_icon {
        margin-right: 10px;
        margin-top: -3px;
        width: 24px;
      }

      svg {
        margin-right: 10px;
        margin-top: -3px;
        width: 24px;
      }

      &.active {
        color: #001e35;
        background: #99c8ed;
        border-radius: 5px;
        padding: 12px 10px;
      }
    }

    .nav-group {
      &.show {
        background: transparent;
        color: #001e35;
      }

      .nav-group-toggle {
        color: #001e35;

        &::after {
          background-image: url(../assets/images/sidebar/arrow.svg);
        }
      }

      .nav-group-items {
        .nav-link {
          padding-left: 45px;
        }
      }
    }
  }
}

.profile_dropdown {
  .avatar {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    .avatar-img {
      width: 45px;
      height: 45px;
      object-fit: cover;
      border: 1px solid #99c8ed;
      background: #99c8ed;
    }
  }

  p {
    margin-bottom: 0;
    color: #1a1a2e;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    display: flex;

    img {
      width: 10px;
      margin-left: 5px;
    }
  }

  span {
    color: #767682;
    font-size: 14px;
  }
}

.title {
  padding: 5px 0 20px;

  h4 {
    margin-bottom: 0;
    color: #1a1a2e;
    font-size: 28px;
  }
}

.user_management {
  .card {
    background: #f8f8f8;
    box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05),
      5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    border: 0;

    .table > :not(caption) > * > * {
      background-color: transparent;
      padding: 15px;

      p {
        margin-bottom: 0;
      }

      .avatar {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }
  }
}

.drop_search {
  display: flex;
  justify-content: end;
  margin: 5px 0 15px;
  flex-wrap: wrap !important;
  gap: 16px !important;

  .btn-group {
    .dropdown-toggle {
      font-family: "Nunito", sans-serif !important;
      color: #121212;
      border: 1px solid #e3e3e3;
      background-color: #fff;
      text-align: left;
      padding-right: 30px;

      &:hover {
        background-color: #fff;
      }

      &::after {
        position: absolute;
        right: 10px;
        top: 50%;
      }
    }

    .dropdown-menu.show {
      width: 100%;
      padding: 5px;

      .dropdown-item {
        font-family: "Nunito", sans-serif !important;
        color: #07070c;

        &:hover {
          background-color: #0176d3;
          color: #fff;
        }
      }
    }
  }

  .form-select {
    font-family: "Nunito", sans-serif !important;
    color: #121212;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    width: 230px;
  }

  .reactSelect {
    & > div {
      width: 190px !important;
      border-color: #e3e3e3 !important;
      padding: 0 3px;
      box-shadow: none;

      &:hover {
        border-color: #e3e3e3;
      }
    }
  }

  .css-1nmdiq5-menu {
    margin-top: 0px !important;
  }

  .input-group {
    width: 220px;
    height: 40px;

    input {
      background-color: #fff;
      border-right: 0;
      border-color: #e3e3e3;
      color: #121212;
      height: 38px;

      &:focus {
        border-color: #e3e3e3;
        box-shadow: none;
      }
    }

    button {
      background-color: #fff;
      border-color: #e3e3e3;
      border-left: 0;
      height: 38px;

      img {
        width: 15px;
        filter: brightness(0.5);
      }
    }
  }

  .btn-primary {
    height: 38px;
  }
}

.entity_pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  p {
    margin-bottom: 0;
  }

  .pagination {
    margin-bottom: 0;

    .page-link {
      background-color: #f8f8f8;
      border-radius: 0 !important;
      color: #1a1a2e;
    }
  }
}

.form-control,
.form-select {
  background-color: #f1f1f1;
  border: 1px solid #a3a3ab;
  border-radius: 5px;
  color: #07070c;
  padding: 8px;
  font-family: "Nunito", sans-serif;

  &:focus {
    border-color: #a3a3ab;
    box-shadow: none;
  }

  &::placeholder {
    color: #07070c;
    font-family: "Nunito", sans-serif;
  }
}

.multiSelectContainer {
  .searchWrapper {
    background-color: #f1f1f1;
    border: 1px solid #a3a3ab;
    border-radius: 5px;
    color: #07070c;
    padding: 8px;
    font-family: "Nunito", sans-serif;
  }

  input {
    margin-top: 0 !important;
  }

  .chip {
    background: #f1f1f1;
    border: 1px solid #3491dc;
    border-radius: 5px;
    color: #3491dc;
  }

  .searchBox {
    width: 85px;
    margin-bottom: 0 !important;
  }

  .closeIcon {
    filter: brightness(0.5);
  }
}

label {
  font-size: 16px;
  margin-bottom: 5px;

  .star {
    color: red;
  }

  .optional {
    color: #a3a3ab;
    font-size: 12px;
  }
}

.modal-header {
  .modal-title {
    font-size: 22px;
  }

  .btn-close {
    filter: brightness(0.1);
    font-size: 14px;
  }

  .edit {
    position: absolute;
    right: 45px;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.plan_cards {
  margin-bottom: 40px;

  h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .card {
    border: 0;
    background: #f8f8f8;
    box-shadow: 35px 36px 20px rgba(0, 0, 0, 0.01), 20px 20px 17px rgba(0, 0, 0, 0.05),
      9px 9px 13px rgba(0, 0, 0, 0.09), 2px 2px 7px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: -webkit-fill-available;
    max-height: 1000%;

    .cardImage {
      position: relative;

      .card-img-top {
        height: 155px;
        object-fit: cover;
      }

      .overlay {
        content: "";
        position: absolute;
        background: linear-gradient(180deg, rgba(241, 241, 241, 0) 23.88%, #f1f1f1 96.27%);
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-top-left-radius: calc(0.375rem - 1px);
        border-top-right-radius: calc(0.375rem - 1px);
      }
    }

    .card-body {
      .plan_title {
        margin-bottom: 10px;

        .card-title {
          font-size: 18px;
          text-transform: capitalize;
          margin-bottom: 0;
          -webkit-line-clamp: 1;
          display: -webkit-box !important;
          -webkit-box-orient: vertical;
          overflow: hidden;

          img {
            margin-right: 5px;
          }
        }

        .btn-group {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            margin-bottom: 10px;

            &:focus {
              background-color: transparent;
            }

            &:hover {
              background-color: transparent;
            }

            &::after {
              display: none;
            }
          }

          .dropdown-menu.show {
            width: 100%;
            padding: 5px;

            .dropdown-item {
              font-family: "Nunito", sans-serif !important;
              color: #07070c;

              &:hover {
                background-color: #0176d3;
                color: #fff;
              }
            }
          }
        }
      }

      .card-text {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

// .editor-toolbar {
//   padding: 5px;
//   border-radius: 5px;
//   border: 1px solid #a3a3ab;

//   .rdw-option-wrapper {
//     &[title="Superscript"] {
//       display: none;
//     }

//     &[title="Subscript"] {
//       display: none;
//     }

//     &[title="Monospace"] {
//       display: none;
//     }
//   }
// }

.planValues {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;
  border-right: 0;
  border: 1px solid #a3a3ab;
  color: #121212;
  padding: 8px;
  border-radius: 5px;

  .form-control {
    border: none;
    background: transparent;
    padding: 0;
    border-radius: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.planVisionModal {
  .image-wrapper {
    position: relative;

    .banner {
      width: 100%;
      height: 180px;
      border-radius: 10px;
      margin-bottom: 10px;
      object-fit: cover;
      position: relative;
    }

    .upload-btn-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.addValues {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border-right: 0;
  border: 1px dashed #a3a3ab;
  color: #121212;
  padding: 8px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;

  p {
    margin-bottom: 0;
    color: #a3a3ab;
  }

  img {
    margin-right: 10px;
    transform: rotate(45deg);
  }
}

.coverImg {
  img {
    border: 0;
    height: 100%;
    object-fit: cover;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
    border-radius: 10px;
  }

  .upload {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 170px;
    object-fit: contain;
    border-radius: 0;
  }

  .card {
    height: 168px;
    background: #f8f8f8;
    border: 1px dashed #a3a3ab;
    border-radius: 10px;
  }
}

.progress_bar {
  .progress {
    height: 7px;

    .progress-bar {
      background-color: #0176d3;
    }
  }

  p {
    margin-bottom: 0;
    color: #0176d3;

    span {
      font-weight: bold;
    }
  }
}

.planDetails {
  padding-bottom: 50px;

  .titleWrapper {
    padding: 5px 0 20px;
    display: flex;
    align-items: center;

    .title {
      h4 {
        padding-right: 20px;
      }
    }

    img {
      margin: 0 10px;
    }
  }

  .para {
    p {
      color: #484858;
      margin-bottom: 0;
    }
  }

  .ownerTeamWrapper {
    .ownerTeam {
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .owner {
        display: flex;
        align-items: center;
        position: relative;

        h4 {
          margin: 0;
          font-size: 18px;
        }

        img {
          margin-left: 10px;
          width: 45px;
          height: 45px;
          border-radius: 100px;
          object-fit: cover;
        }
        .ownerWrap {
          display: flex;
          align-items: center;
          position: relative;

          .cardOwner {
            position: absolute;
            top: 30px;
            left: -20px;
            z-index: 999;
            width: 225px;
            padding: 12px;
            background: #f1f1f1;
            border: 1px solid #a3a3ab;
            box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05),
              5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1),
              0px 0px 0px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            ul {
              padding: 0;
              margin: 0;
              list-style: none;
              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0;
                  cursor: pointer;
                }
                p {
                  display: flex;
                  align-items: center;
                  margin: 0;
                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 100px;
                    border: 1px solid white;
                    margin-right: 8px;
                  }
                  .plus {
                    width: 20px;
                    height: 20px;
                    border: 0;
                    filter: invert(1);
                  }
                }
                .del {
                  width: 15px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .team {
        display: flex;
        align-items: center;
        position: relative;

        h4 {
          margin: 0;
          font-size: 18px;
        }

        .teamWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #99c8ed;
          border-radius: 10px;
          padding: 10px 20px;
          margin-left: 10px;

          img {
            margin-right: 10px;
          }

          h5 {
            color: #0176d3;
            margin: 0;
            font-size: 18px;
          }
        }
        .teamWrapper1 {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;

          img {
            margin-right: -10px;
            border: 1px solid white;
            border-radius: 100px;
            width: 35px;
            height: 35px;
          }

          h5 {
            color: #0176d3;
            margin: 0;
            font-size: 18px;
          }
        }
        .card {
          position: absolute;
          top: 47px;
          right: -10px;
          z-index: 999;
          width: 235px;
          padding: 12px;
          background: #f1f1f1;
          border: 1px solid #a3a3ab;
          box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05),
            5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1),
            0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
                cursor: pointer;
              }
              p {
                display: flex;
                align-items: center;
                margin: 0;
                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 100px;
                  border: 1px solid white;
                  margin-right: 8px;
                }
                .plus {
                  width: 20px;
                  height: 20px;
                  border: 0;
                  filter: invert(1);
                }
              }
              .del {
                width: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .plansCard {
    margin: 10px 0 30px;

    .card {
      filter: drop-shadow(20px 20px 11px rgba(0, 0, 0, 0.01))
        drop-shadow(11px 11px 9px rgba(0, 0, 0, 0.05)) drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.09))
        drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
      border: 0;

      .card-title {
        margin-bottom: 15px;
      }

      .card-text {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .card_footer {
        border-top: 1px solid #e3e3e3;
        cursor: pointer;
      }
    }
  }
}

.listTimeline {
  .nav-tabs {
    margin-bottom: 20px;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: black;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid #0176d3;
    font-family: "Lato", sans-serif !important;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: transparent;
    color: #0176d3;
    border-bottom: 2px solid #0176d3;
  }

  .nav-tabs .nav-link {
    color: black;
    font-family: "Nunito", sans-serif !important;
    padding: 0 0 3px 0;
    margin: 0 15px 0 0;
    cursor: pointer;
  }
}

.listView {
  .accordion .accordion-button:focus {
    box-shadow: none;
  }

  .accordion .accordion-header button {
    text-transform: capitalize;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .accordion .accordion-item {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .accordion .accordion-button:not(.collapsed) {
    color: #333333;
    background-color: transparent;
    box-shadow: none;
  }

  .accordion .accordion-button {
    padding: 0;
  }

  .accordion .accordion-body {
    padding: 10px 0 0 0;
  }

  .accordion .accordion-button:not(.collapsed)::after {
    background-size: 13px;
    margin-top: -5px;
    filter: grayscale(1);
  }

  .accordion .accordion-button::after {
    background-size: 13px;
    margin-top: 7px;
    filter: grayscale(1);
  }

  .accordionHeader {
    display: flex;
    align-items: center;

    .colorPicker {
      display: flex;
      align-items: center;
      background: #f1f1f1;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      width: 500px;
      padding: 5px 10px;
      margin-left: 10px;

      .colorInput {
        width: 20px;
        height: 20px;
        border: none;
        outline: 0;
        padding: 0;
        margin-right: 10px;
      }

      .focusInput {
        margin: 0;
        // color: #ad8e20;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        background-color: transparent;
        border: 0;
        outline: 0;
      }
    }

    .focus {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      label {
        color: #b83c3c;
        font-weight: bold;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        margin: 0;
      }
    }

    .trash {
      position: absolute;
      right: 30px;
      filter: brightness(0.5);
      width: 20px;
    }
  }

  .accordionBody {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .objective {
          background: #f8f8f8;
          border: 1px solid #b8b8b8;
          border-radius: 10px;
          padding: 10px;
          display: flex;
          justify-content: space-between;

          .arrow {
            width: 2%;

            img {
              vertical-align: top;
              margin-top: 2px;
              width: 8px;
            }
          }

          .objectiveInfo {
            width: 30%;

            h5 {
              font-size: 16px;
              color: #1a1a2e;
            }

            p {
              font-size: 14px;
              color: #0176d3;
              margin-bottom: 5px;
            }

            span {
              font-size: 14px;
              color: #767682;
            }
          }

          .edit {
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            img {
              margin: 0 5px;
              width: 20px;
            }
          }

          .team {
            width: 18%;
            margin-right: 20px;

            h6 {
              color: #1a1a2e;
              font-size: 16px;
            }

            img {
              width: 35px;
              height: 35px;
              border-radius: 100px;
              object-fit: cover;
              margin-right: -10px;
            }

            span {
              width: 35px;
              height: 35px;
              border-radius: 100px;
              border: 1px dashed #0176d3;
              background: #f1f1f1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: bold;
            }
          }

          .progressBar {
            width: 25%;
            margin-right: 20px;

            p {
              color: #1a1a2e;
              margin-bottom: 0;
            }

            .success {
              background: linear-gradient(180deg, #a5f2ce 0%, #59e781 100%);
            }

            .danger {
              background: linear-gradient(180deg, #ffa5a5 0%, #eb6b6b 100%);
            }

            .pending {
              background: linear-gradient(180deg, #dddddd 0%, #cacaca 100%);
            }
          }

          .submitBtn {
            width: 15%;

            .btn {
              border-radius: 44px;
              font-size: 14px;
              width: 100%;
            }

            .inprogress {
              background: #a5f2ce;
              color: #2e8e62;
            }

            .danger {
              background: #ffa5a5;
              color: #b83c3c;
            }

            .pending {
              background: #d4d4d4;
              color: #737373;
            }
          }
        }
      }
    }
  }

  .addQuestion {
    p {
      background: #f8f8f8;
      border: 1px solid #b8b8b8;
      border-radius: 10px;
      margin: 0;
      padding: 10px;
      color: #1a1a2e;

      img {
        filter: invert(1);
        margin-top: -4px;
        margin-right: 10px;
      }
    }
  }
}

.offcanvas {
  width: 550px !important;
  height: 100%;
  overflow-y: scroll;

  .offcanvas-header {
    img {
      margin: 0 15px;
    }
  }

  .offcanvas-body {
    .tab-pane {
      .details {
        .progressLine {
          display: flex;
          align-items: end;
          justify-content: space-between;
          margin: 30px 0;

          .progressBtn {
            h5 {
              font-size: 16px;
            }

            .btn {
              border-radius: 44px;
              font-size: 14px;
              width: 160px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .progress {
              background: #a5f2ce;
              color: #2e8e62;
            }

            .danger {
              background: #ffa5a5;
              color: #b83c3c;
            }

            .pending {
              background: #d4d4d4;
              color: #737373;
            }
          }

          .percent {
            text-align: end;

            h5 {
              font-size: 16px;
              margin: 0;
            }

            p {
              margin-bottom: 0;
              color: #8a8a8a;
              font-size: 14px;
            }
          }
        }

        .success {
          background: linear-gradient(180deg, #a5f2ce 0%, #59e781 100%);
        }

        .danger {
          background: linear-gradient(180deg, #ffa5a5 0%, #eb6b6b 100%);
        }

        .pending {
          background: linear-gradient(180deg, #dddddd 0%, #cacaca 100%);
        }

        .lineChart {
          margin: 30px 0 20px;
        }

        .cross {
          margin-bottom: 10px;
          height: 41px;
          border: 1px solid #a3a3ab;
          border-radius: 5px;
        }

        .objectiveForm {
          input {
            margin-bottom: 10px;
          }

          .editor-color {
            background-color: #f1f1f1;

            .rdw-editor-toolbar {
              padding: 0;
              border-radius: 0;
              border-bottom: 1px solid #dfdfdf;
              background: #f1f1f1;

              .rdw-option-wrapper {
                background: #f1f1f1;
              }
            }
          }

          .hide {
            background-color: #d8dbe0;
            border-color: #b1b7c1;
          }
        }
      }

      .criteria {
        background: #f8f8f8;
        border: 1px solid #b8b8b8;
        border-radius: 10px;
        margin-top: 20px;
        padding: 10px;

        .team {
          h6 {
            color: #1a1a2e;
            font-size: 16px;
          }

          img {
            width: 35px;
            height: 35px;
            border-radius: 100px;
            object-fit: cover;
            margin-right: -10px;
          }
        }
      }

      .comment_input {
        .comments {
          margin-top: 20px;

          .commnetBox {
            .userComment {
              background: #f8f8f8;
              border: 1px solid #b8b8b8;
              border-radius: 10px;
              margin-top: 10px;
              padding: 10px;

              .userInfo {
                display: flex;
                justify-content: space-between;

                img {
                  width: 35px;
                  height: 35px;
                  object-fit: cover;
                  border-radius: 100px;
                  margin-right: 15px;
                }

                h5 {
                  font-size: 16px;
                  margin: 0;
                }

                p {
                  margin-bottom: 0;
                  font-size: 14px;
                }

                span {
                  font-size: 14px;
                  color: #8a8a8a;
                }
              }

              .reply {
                display: flex;
                align-items: baseline;
                p {
                  cursor: pointer;
                }

                .btn-group {
                  .dropdown-toggle {
                    border: 0;
                    padding: 0;
                    margin-bottom: 10px;

                    img {
                      width: 30px;
                      height: 30px;
                      margin: 0;
                    }

                    &:focus {
                      background-color: transparent;
                    }

                    &:hover {
                      background-color: transparent;
                    }

                    &::after {
                      display: none;
                    }
                  }

                  .dropdown-menu.show {
                    width: 100%;
                    padding: 5px;

                    .dropdown-item {
                      font-family: "Nunito", sans-serif !important;
                      color: #07070c;

                      &:hover {
                        background-color: #0176d3;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .notePad {
          position: absolute;
          bottom: 0px;
          width: 100%;
          padding: 1rem;
          left: 0;
          right: 0;
        }
      }
    }
  }
}

.planTeam {
  .createPlan {
    .card {
      background: #f8f8f8;
      box-shadow: 35px 36px 20px rgba(0, 0, 0, 0.01), 20px 20px 17px rgba(0, 0, 0, 0.05),
        9px 9px 13px rgba(0, 0, 0, 0.09), 2px 2px 7px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 0;
      height: 11rem;

      .img-fluid {
        height: 100%;
        object-fit: cover;
        height: 11rem;
      }

      .card-title {
        font-size: 14px;
      }

      .card-text {
        color: #5c5c5c;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 12px;
      }
    }
  }

  .myTeam {
    .card {
      background: #f8f8f8;
      box-shadow: 35px 36px 20px rgba(0, 0, 0, 0.01), 20px 20px 17px rgba(0, 0, 0, 0.05),
        9px 9px 13px rgba(0, 0, 0, 0.09), 2px 2px 7px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 0;
      padding: 1rem;
      height: 11rem;

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;

        h5 {
          color: #07070c;
          margin-bottom: 0;
          font-size: 14px;
        }
      }

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          margin-right: 5px !important;
          // width: 185px !important;
        }

        .swiper-button-next:after,
        .swiper-rtl .swiper-button-prev:after {
          content: " ";
          background-image: url(../../public/images/pagiright.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 27px;
          height: 27px;
        }

        .swiper-button-disabled {
          display: none;
        }

        .swiper-button-prev:after,
        .swiper-rtl .swiper-button-next:after {
          content: " ";
          background-image: url(../../public/images/pagiright.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 27px;
          height: 27px;
          transform: rotate(178deg);
        }

        .teamSlider {
          .card {
            padding: 5px;
            background: #f8f8f8;
            border: 1px solid #b8b8b8;
            border-radius: 10px;
            box-shadow: none;
            height: auto;

            h5 {
              font-size: 12px;

              img {
                filter: brightness(0.1);
                margin-right: 3px;
              }
            }

            .ongoingMember {
              display: flex;
              justify-content: space-between;

              .ongoing {
                width: 45%;

                h6 {
                  font-size: 12px;
                }

                p {
                  font-size: 12px;
                  margin-bottom: 0;

                  img {
                    margin-right: 3px;
                    width: 16px;
                  }
                }
              }

              .ongoing_team {
                width: 45%;

                h6 {
                  font-size: 12px;
                }

                .ongoing_img {
                  display: flex;
                  align-items: center;

                  img {
                    margin-left: -10px;
                    width: 25px;
                    height: 25px;
                    border-radius: 100px;
                    object-fit: cover;
                  }

                  span {
                    width: 25px;
                    height: 25px;
                    border-radius: 100px;
                    border: 1px dashed #0176d3;
                    background: #f1f1f1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    margin-left: -10px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .btn-primary {
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 10px;

    img {
      width: 18px;
      margin-right: 5px;
    }
  }
}

.homeFocus {
  background: #fff;
  box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05),
    5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
}

.triangleProgress {
  margin: 15px 0 25px;

  p {
    color: #8a8a8a;
    margin-bottom: 5px;
  }
}

.btn-group {
  .dropdown-toggle {
    border: 0;
    padding: 0;
    margin-bottom: 10px;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu.show {
    width: 100%;
    padding: 5px;

    .dropdown-item {
      font-family: "Nunito", sans-serif !important;
      color: #07070c;

      &:hover {
        background-color: #0176d3;
        color: #fff;
      }
    }
  }
}

.focusArea {
  .card {
    background: #f8f8f8;
    box-shadow: 35px 36px 20px rgba(0, 0, 0, 0.01), 20px 20px 17px rgba(0, 0, 0, 0.05),
      9px 9px 13px rgba(0, 0, 0, 0.09), 2px 2px 7px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 0;
    padding: 1rem;
    margin-bottom: 20px;

    .headers {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .focus {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }

        label {
          color: #b83c3c;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          font-size: 14px;
          margin: 0;
        }
      }

      .btn-group {
        .dropdown-toggle {
          border: 0;
          padding: 0;
          margin-bottom: 10px;

          &:focus {
            background-color: transparent;
          }

          &:hover {
            background-color: transparent;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu.show {
          width: 100%;
          padding: 5px;

          .dropdown-item {
            font-family: "Nunito", sans-serif !important;
            color: #07070c;

            &:hover {
              background-color: #0176d3;
              color: #fff;
            }
          }
        }
      }
    }

    .list {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 50%;
      margin-right: 20px;

      li {
        font-size: 14px;
        margin-bottom: 25px;

        img {
          margin-right: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn {
      border-radius: 44px;
      font-size: 14px;
      width: 50%;
    }

    .inprogress {
      background: #a5f2ce;
      color: #2e8e62;
    }

    .danger {
      background: #ffa5a5;
      color: #b83c3c;
    }

    .pending {
      background: #d4d4d4;
      color: #737373;
    }
  }
}

.teamWrapperCards {
  h5 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .card {
    background: #f8f8f8;
    box-shadow: 35px 36px 20px rgba(0, 0, 0, 0.01), 20px 20px 17px rgba(0, 0, 0, 0.05),
      9px 9px 13px rgba(0, 0, 0, 0.09), 2px 2px 7px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 0;
    padding: 1rem;
    margin-bottom: 20px;

    .headers {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .focus {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          filter: brightness(0.1);
        }

        label {
          color: #001e35;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          font-size: 16px;
          margin: 0;
        }
      }

      .btn-group {
        .dropdown-toggle {
          border: 0;
          padding: 0;
          margin-bottom: 10px;

          &:focus {
            background-color: transparent;
          }

          &:hover {
            background-color: transparent;
          }

          &::after {
            display: none;
          }
        }

        .dropdown-menu.show {
          width: 100%;
          padding: 5px;

          .dropdown-item {
            font-family: "Nunito", sans-serif !important;
            color: #07070c;

            &:hover {
              background-color: #0176d3;
              color: #fff;
            }
          }
        }
      }
    }

    .list {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 50%;
      margin-right: 20px;

      li {
        font-size: 14px;
        margin-bottom: 10px;

        img {
          margin-right: 10px;
          filter: brightness(0.1);
        }

        p {
          font-weight: bold;
          margin-bottom: 0;
        }

        .person {
          filter: none;
          margin-top: 10px;
          width: 35px;
          height: 35px;
          border-radius: 100px;
          object-fit: cover;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .team_member {
      p {
        font-weight: bold;
        margin-bottom: 0;
      }

      .members {
        display: flex;
        align-items: center;
        margin-top: 10px;

        img {
          margin-left: -10px;
          width: 35px;
          height: 35px;
          border-radius: 100px;
          object-fit: cover;
        }

        span {
          width: 35px;
          height: 35px;
          border-radius: 100px;
          border: 1px dashed #0176d3;
          background: #f1f1f1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          margin-left: -10px;
          font-weight: bold;
        }
      }
    }
  }
}

.kanban-board-wrapper {
  .success {
    background: linear-gradient(180deg, #a5f2ce 0%, #59e781 100%);
  }

  .danger {
    background: linear-gradient(180deg, #ffa5a5 0%, #eb6b6b 100%);
  }

  .pending {
    background: linear-gradient(180deg, #dddddd 0%, #cacaca 100%);
  }

  .kanban_row {
    overflow-x: scroll;
    padding-right: 20px !important;
    padding-left: 20px !important;

    .kanban_col {
      width: 470px !important;
      max-width: 470px !important;
      background-color: #99c8ed;

      .kanban_card {
        width: 438px;
        background: #f8f8f8;
        box-shadow: 35px 36px 20px rgba(0, 0, 0, 0.01), 20px 20px 17px rgba(0, 0, 0, 0.05),
          9px 9px 13px rgba(0, 0, 0, 0.09), 2px 2px 7px rgba(0, 0, 0, 0.1),
          0px 0px 0px rgba(0, 0, 0, 0.1);

        .kanban_h5,
        .kanban_label {
          color: #001e35;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          font-size: 16px;
          margin: 0;
        }
        .avatar {
          width: 50px !important;
          height: 50px !important;
          border-radius: 50% !important;
          .avatar-img {
            height: 100%;
          }
        }
        .kanban_span {
          color: #d6d3d3;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .IcreasedecreaseBtn {
    display: flex;
    margin-bottom: 10px;

    .form-control {
      margin-bottom: 0 !important;
      border-radius: 0;
      border: 1px solid #a3a3ab;
      text-align: center;
    }

    button {
      width: 70px;
      border: 1px solid #a3a3ab;
      font-size: 22px;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .updateTask {
    // border: 1px solid #a3a3ab;
    // border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;

    .form-check {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      input {
        margin-bottom: 5px !important;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .form-check-input {
        border: 1px solid #8a8a8a;
        background-color: transparent;
        filter: brightness(0.5);
        border-radius: 0;
        padding: 10px;
      }

      .form-check-label {
        margin-bottom: 0;
        margin-left: 7px;
      }

      .del {
        text-decoration: line-through;
      }
    }
  }

  // =================================

  .custom-cursor {
    cursor: pointer !important;
  }

  .error-outline {
    color: #b83c3c;
    box-shadow: #b83c3c 0px 1px 1px, #b83c3c 0px 0px 0px 1px;
  }

  .error-text {
    color: #b83c3c;
  }

  .hide-toolbar {
    display: none !important;
  }

  .secondary {
    color: #000015 !important;
    background-color: #9da5b1 !important;
  }

  .editor-color {
    padding: 10px;
    display: block;
    background-color: #f1f1f1;
    border: 1px solid #aaaab1;
    border-radius: 5px;
    height: 100px;
    overflow-y: scroll;
    margin-bottom: 13px;

    .public-DraftStyleDefault-block {
      margin: 0 !important;
    }

    .rdw-editor-toolbar {
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid #dfdfdf;
      background: #f1f1f1;

      .rdw-option-wrapper {
        background: #f1f1f1;
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .edit-drawer {
    display: flex;
    justify-content: end;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.reports_wrapper {
  & > h5 {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #07070c;
    padding: 0;
  }
  .reports_cards {
    width: 389px;
    // height: 289px;
    padding: 0px 0px 16px 0px;
    border-radius: 10px;
    background: #f8f8f8;
    box-shadow: 2px 2px 7px 0px #0000001a;
    flex-shrink: 0;

    & > .img_banner {
      width: 100%;
      height: 166px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .report_headers {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .focus {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          filter: brightness(0.1);
        }

        label {
          font-family: "Lato", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #001e35;
        }
      }
    }

    .report_footer {
      h3 {
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 19px;
        letter-spacing: 0em;
        color: #07070c;
      }
    }
  }
}

.new_reports_wrapper {
  .last_update {
    span {
      font-family: "Nunito", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #8a8a8a;
    }
  }

  .new_reports_editor {
    .reports_editor_toolbaar {
      background-color: #fff;
      padding: 0;
      border-radius: 10px;
      min-height: 688px;
      .rdw-editor-wrapper {
        .rdw-editor-toolbar {
          padding: 16px 32px 16px 32px;
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: flex-end;
          border-bottom: 1px solid #a3a3ab;
          .rdw-inline-wrapper {
            .rdw-option-wrapper {
              border: none;
            }
          }
          .rdw-image-modal {
            z-index: 1100 !important;
            top: 25px !important;
            // right: 100% !important;
            left: 200px !important;
          }
          .rdw-image-modal {
            top: 35px !important;
            left: -100px !important;
          }
          .rdw-link-modal {
            left: -150px;
          }
          .rdw-list-wrapper {
            display: flex;
            gap: 16px;
          }
        }
        .rdw-editor-main {
          padding: 16px 32px 16px 32px;
        }
      }
    }
  }
}

.table_modal {
  .modal-header {
    h3 {
      font-family: "Lato" !important;
      font-size: 22px !important;
      font-weight: 700 !important;
      line-height: 38px !important;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
    }
  }
  .modal-body {
    h3 {
      font-family: "Nunito" !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      line-height: 19px !important;
      letter-spacing: 0em;
      text-align: left;
      color: #121212 !important;
    }
    .form-label {
      font-family: "Nunito";
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

.report_dashboard_modal {
  .modal-header {
    h3 {
      font-family: "Lato" !important;
      font-size: 22px !important;
      font-weight: 700 !important;
      line-height: 38px !important;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
    }
  }
  .dashboard-modal-left {
    min-height: 251px;
    min-width: 65%;
    border: 1px solid #d4d4d4 !important;
    border-radius: 10px !important;
    .report_headers {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .focus {
        display: flex;
        align-items: center;
        gap: 16px;

        label {
          font-family: "Lato", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #001e35;
        }
      }
    }
    .tab_content_left_1 {
      .dashboard_card {
        text-align: center;
        h3 {
          font-family: "Lato";
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          color: #07070c;
          span {
            font-family: "Lato";
            font-size: 32px !important;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 0em;
          }
        }
        h4 {
          font-family: "Lato";
          font-size: 18px !important;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          color: #4bd394 !important;
        }
      }
    }
  }
  .dashboard-modal-right {
    // min-width: 45%;
    h3 {
      font-family: "Nunito" !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      line-height: 19px !important;
      letter-spacing: 0em;
      text-align: left;
      color: #121212 !important;
    }
    .dashboard_tabs {
      gap: 16px;
      flex-flow: nowrap !important;
      .nav-link {
        // min-width: 80px;
        // height: 80px;
        padding: 8px;
        border: 1px solid #a3a3ab;
        border-radius: 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        background: linear-gradient(0deg, #f1f1f1, #f1f1f1), linear-gradient(0deg, #a3a3ab, #a3a3ab);
        font-family: "Nunito";
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        white-space: nowrap;
        color: #07070c;
        &.active {
          background: linear-gradient(0deg, #99c8ed, #99c8ed),
            linear-gradient(0deg, #0176d3, #0176d3) !important;
          color: #0176d3 !important;
          border: 1px solid #0176d3 !important;
        }
      }
    }
    .dashboard_tabs_content {
      padding: 16px 0 16px 0;
      h3 {
        font-family: "Nunito" !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0em;
        text-align: left;
        color: #121212 !important;
      }
      .form-label {
        font-family: "Nunito";
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

.report_share_modal_body {
}

.rdw-image-modal {
  left: -150px !important;
  top: -290px !important;
}

.listView_table {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-200%);
  pointer-events: none;
}

.wrap-switch {
  p {
    font-size: 14px;
  }
}

.ql-editor {
  min-height: 100vh;
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.orgCard {
  height: 11rem !important;

  .orgTitle {
    color: #767682;
  }

  .orgDes {
    height: 3.5rem;
  }
  .orgBtn {
    border-radius: 0.8rem;
  }
  .orgOuter {
    height: 7.5rem;
  }
}

.teamOwnerImg {
  width: 35px !important;
  height: 35px !important;
}
.card_footer {
  border-top: 1px solid #e3e3e3;
  cursor: pointer;
}
.imgComment {
  img {
    border-radius: unset !important;
  }
}

.user-password-input {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  right: 0;
}
